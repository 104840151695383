import React, { useRef, useEffect, useState } from 'react';
/**
 * Components
 */
//  import HomePage from "@Components/Header/Header"
import BannerPage from "@Components/Home/HomeBanner/Banner"
import TrustedPage from "@Components/Home/Trusted/Trusted"
import AboutPage from "@Components/Home/AboutRecruitment/About"
import TeamPage from "@Components/Home/OurTeam/Team"
import ContactPage from "@Components/Home/ContactUs/Contact"
import StoriesPage from "@Components/Home/SuccessStories/Stories"
import BoxContent from "@Components/AdviceCentre/BoxContent/BoxContent"
import AboutUs from "@Components/AdviceCentre/AboutUs/AboutUs"
import AdviceTeam from "@Components/AdviceCentre/AdviceTeam/AdviceTeam"
import Reviews from "@Components/AdviceCentre/GoogleReviews/Reviews"
import BreadcrumbPage from "@Components/BreadcrumbsHome.js";
import Customers from "@Components/AdviceCentre/Customers/Customers"
import QuestionList from "@Components/AdviceCentre/QuestionList/QuestionList"
import WhyUs from "@Components/AdviceCentre/WhyUs/WhyUs"
import Blog from "@Components/AdviceCentre/Blog/Blog"
import Contact from "@Components/Contact/Contact"
import Videos from "@Components/AdviceCentre/Videos/Videos"
import CareersFormData from "@Components/AdviceCentre/CareersForm/CareersForm"
import BlogSection from "@Components/AdviceCentre/BlogSection/BlogSection"
import 'bootstrap/dist/css/bootstrap.min.css';
import "@scss/bootstrap-overrides.scss"
import "@scss/global.scss";
import "@scss/grunticon.scss";

const Modules = (props) => {
    // console.log(props)
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      }) 
      window.addEventListener("scroll", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    }) 
       
    }, [])
    const myRef = useRef(null)
    const myRefform = useRef(null)
    const executeScrollform = () => {
        myRefform.current.scrollIntoView({
            behavior: "smooth"
        })
    }
    const executeScroll = () => {
        myRef.current.scrollIntoView({
            behavior: "smooth"
        })
    }
    return (

        <>

            {props.Modules && props.Modules?.length > 0 ? props.Modules.map((Module, i) => {
                return (
                    <React.Fragment>
                        {Module.Banner_Title &&
                            <>
                                <BannerPage
                                    Pagename={props.Page.Pagename}
                                    Title={Module.Banner_Title}
                                    Banner_Content={Module.Banner_Content}
                                    Banner_CTA_1_Label={Module.CTA?.Banner_CTA_1_Label}
                                    Banner_CTA_1_URL={Module.CTA?.Banner_CTA_1_URL}
                                    Link_Type={Module.CTA?.Link_Type}
                                    Banner_Image={Module.Banner_Image}
                                    Embed_Video_URL={Module.Embed_Video_URL}
                                    Upload_Video={Module.Upload_Video}
                                    Show_Review={Module.Show_Review}
                                    Show_Search={Module.Show_Search}
                                    executeScroll={executeScroll}
                                    executeScrollform={executeScrollform}
                                    imagetransforms={props.Page.Image_Transforms}
                                    id={props.Page.id}
                                    Mobile_Banner_Image={props.Page.Mobile_Banner_Image}
                                />
                                {Module.Show_Companies &&
                                    <TrustedPage />
                                }

                            </>
                        }
                        {Module.Show_Content &&
                            <BlogSection
                                isPage={true}
                                page={Module}
                                Title={props.Page.Pagename}
                            />
                        }
                        {Module.Select_Collection === 'Contact' &&
                            <Contact contact={props.contact} />
                        }
                        {Module.Select_Collection === 'Teams' &&
                            <AdviceTeam Title={Module.Section_Title} Content={Module.Content} />
                        }
                        
                        {Module.Select_Collection === 'Customers' &&
                            <>
                                <Customers Title={Module.Section_Title} Content={Module.Content} />
                            </>
                        }
                        {Module.Select_Collection === 'Reviews' &&
                            <>
                                <Reviews Title={Module.Section_Title} Content={Module.Content} />
                            </>
                        }
                        {Module.Select_Collection === 'Blogs' &&
                            <Blog Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Select_Collection === 'Videos' &&
                            <Videos Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Select_Collection === 'faqs' &&
                            <QuestionList Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Select_Collection === 'Careers' &&
                            <>
                                <div ref={myRef} id="scroll_view"></div>
                                <CareersFormData collection={Module.Select_Collection} Title={Module.Section_Title} Content={Module.Content} />
                            </>
                        }
                        {Module.Select_Collection === 'Talent' &&
                            <CareersFormData collection={Module.Select_Collection} Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Select_Collection === 'Candidate' &&
                            <CareersFormData collection={Module.Select_Collection} Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Select_Collection === 'Employer' &&
                            <CareersFormData collection={Module.Select_Collection} Title={Module.Section_Title} Content={Module.Content} />
                        }
                        {Module.Block_Title &&
                            <WhyUs
                                Alignment={Module.Alignment}
                                Title={Module.Block_Title}
                                Content={Module.Content}
                                Description={Module.Description}
                                Embed_Video_URL={Module.Embed_Video_URL}
                                Image={Module.Image}
                                Upload_Video={Module.Upload_Video}
                            />
                        }

                        {Module.About_Title && Module.Type === "Dashboard" &&
                            <AboutPage
                                Title={Module.About_Title}
                                Count={Module.Count}
                                Count_Ext={Module.Ext_1}
                                Plus_Count={Module.Plus_Count}
                                Plus_Count_Ext={Module.Ext_3}
                                Percentage={Module.Percentage}
                                Percentage_Ext={Module.Ext_2}
                                Ext={Module.Ext_4}
                                Egestas={Module.Egestas}
                                Count_Text={Module.Count_Text}
                                Plus_Count_Text={Module.Plus_Count_Text}
                                Percentage_Text={Module.Percentage_Text}
                                Egestas_Text={Module.Egestas_Text}
                                Description={Module.Description}
                                About_CTA={Module.About_CTA}
                                

                            />
                        }
                        {Module.About_Title && Module.Type === "VideoImage" &&
                            <AboutUs
                                Title={Module.About_Title}
                                Description={Module.Description}
                                Content={Module.Content}
                                Image={Module.Image}
                                Embed_Video_URL={Module.Embed_Video_URL}
                                About_CTA={Module.About_CTA}
                                Alignment={Module.Choose_Alignment}
                                Upload_Video={Module.Upload_Video}

                            />
                        }
                        {Module.Image_Title &&
                            <TeamPage
                                Title={Module.Image_Title}
                                Description={Module.Description}
                                Content={Module.Content}
                                Add_CTA={Module.Add_CTA}
                                Video_Url={Module.Video_Url}
                                Upload_Video={Module.Upload_Video}
                                Image={Module.Image}
                            />
                        }
                        {Module.Team_Title &&
                            <StoriesPage
                                Team={true}
                                Title={Module.Team_Title}
                                Description={Module.Description}
                                Content={Module.Content}
                                select_customers={Module.Select_Team}

                            />
                        }
                        {Module.Story_Title &&
                            <StoriesPage
                                Title={Module.Story_Title}
                                Story_CTA={Module.Story_CTA}
                                Content={Module.Content}
                                select_customers={Module.select_customers}

                            />
                        }
                        {Module.Services &&
                            <BoxContent
                                Services={Module.Services}
                            />
                        }
                    </React.Fragment>
                )

            }) : ''
            }
            {props.Page?.Show_Contact &&
                <>
                    {/* {renderComponent && */}
                    <ContactPage myRefform={myRefform} Title={props.Page.Pagename} />
                    {/* } */}
                </>
            }
            {props.Page.Pagename !== "Home" &&
            <BreadcrumbPage Page={props.Page.Pagename} alias={props.Page.Alias}/>
            }
        </>

    )
}
export default Modules
