import React, {useEffect} from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/layout";
import SEO from "@Components/seo";
import { graphql } from 'gatsby'
// import { getCookie } from "@Components/myaccount/utils/cookie"
import { useMatch } from "@reach/router"
import PageModules from '@Components/modules';
import BlogDetails from '@Components/BlogDetails/BlogDetails'
import { BLOGS_PAGE_URL } from "@Components/common/site/constants";
// import $ from "jquery"
const DefaultTemplate = (props) => {
  
  const blogDetails = useMatch(BLOGS_PAGE_URL.alias+"/:item/")
  const Modules = props.data.glstrapi?.page?.Modules
  const Page = props.data.glstrapi?.page
  const contact = props.data.glstrapi?.contact
  // let wishlistItems = getCookie("favourites")
  // useEffect(() => {
  //   let wishlistItems = getCookie("favourites")
  //   $(".wishlist-count").text(wishlistItems.length)
  //   if (wishlistItems.length) $("html").addClass("has-wishlist")
  // }, [])
if(blogDetails !== null){
    var URL = blogDetails.item
      var page_url = BLOGS_PAGE_URL.alias.split( '/' )
    return(<>
         <BlogDetails slug={URL} alias={page_url.pop()}/>
        </>
    )
  }
  else{

  return (
    <Layout Modules={Modules ? Modules : ""} contact={contact}>
      <SEO title={Page ? Page.Meta_Title : ''} description={Page ? Page.Meta_Description : ''} />
      <Helmet
        bodyAttributes={{
          class: `templates-default-template alias-${Page ? Page.Alias : ''} homepage ${Page ? Page.Custom_CSS_Class : ''} `
        }}
      />
      <PageModules
        Modules={Modules}
        Page={Page}
        contact={contact}
      />

    </Layout>
  )
  }
}

export default DefaultTemplate


export const pageQuery = graphql`
  query DeafultQuery($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        id
      Alias
      Custom_CSS_Class
      Layout
      Meta_Description
      Meta_Title
      Pagename
      Publish
      Image_Transforms
      Mobile_Banner_Image {
        url
        alternativeText
        url_sharp {
          childImageSharp {
            gatsbyImageData(formats: AUTO
              width: 1500
              quality: 80
              layout: FIXED
              transformOptions: {cropFocus: CENTER, fit: COVER}
            )
          }
        }
      }
      Show_Contact
      Modules {
        ... on GLSTRAPI_ComponentModuleAbout {
          id
          Choose_Alignment
          Type
          Count
          Plus_Count
          Percentage
          Egestas
          Count_Text
          Plus_Count_Text
          Ext_1
          Percentage_Text
          Ext_2
          Egestas_Text
          Embed_Video_URL
          Ext_3
          Ext_4
          Description
          About_Title
          Content
          About_CTA {
            CTA_1_Label
            CTA_1_URL {
              Alias
            }
          }
          Upload_Video {
            url
          }
          Image {
            alternativeText
            url
          }
        }
        ... on GLSTRAPI_ComponentModuleBanner {
          id
          Banner_Content
          Banner_Image {
            alternativeText
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 1500
                  quality: 80
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )
              }
            }
          }
          Banner_Title
          Upload_Video {
            url
          }
          Embed_Video_URL
          Show_Review
          Show_Companies
          Show_Search
          CTA {
            Banner_CTA_1_Label
            Banner_CTA_1_URL {
              Alias
            }
            Link_Type
          }
        }
        ... on GLSTRAPI_ComponentModuleStories {
          id
          Content
          Story_Title
          select_customers {
            Name
            Designation
            Embed_Video_URL
            Image {
              alternativeText
              url
            }
          }
          Story_CTA {
            CTA_1_Label
            CTA_1_URL {
              Alias
            }
          }
        }
        ... on GLSTRAPI_ComponentModuleCollections {
          id
          Select_Collection
          Section_Title
          Content
        }
        ... on GLSTRAPI_ComponentModuleContentBlock {
          id
          Add_Blocks {
            Content
            Image {
              alternativeText
              url
            }
          }
        }
        ... on GLSTRAPI_ComponentModuleServicesTiles {
          id
          Services {
            CTA_Label
            Content
            Title
            Icon {
              alternativeText
              url
            }
            CTA_URL {
              Alias
            }
          }
        }
        ... on GLSTRAPI_ComponentModuleImageCta {
          id
          Image_Title
          Description
          Content
          Video_Url
          Add_CTA {
            Label
            URL {
              Alias
            }
            CTA_BLOG_URL {
              URL
            }
          }
          Upload_Video {
            url
          }
          Image {
            alternativeText
            url
          }
        }
        ... on GLSTRAPI_ComponentModuleTwoColBlock {
          id
          Alignment
          Block_Title
          Content
          Description
          Embed_Video_URL
          Upload_Video {
            url
          }
          Image {
            alternativeText
            url
          }
        }
        ... on GLSTRAPI_ComponentModuleAddBlocks {
          id
          Show_Content
          Content          
        }
        ... on GLSTRAPI_ComponentModuleTeam {
          id
          Content
          Description
          Team_Title
          Select_Team {
            Designation
            Name
            Linked_In
            Embed_Video_URL
            Image {
              alternativeText
              url
            }
          }
        }
      }
      }

      contact(publicationState: LIVE) {
        Address
        Email
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Phone
        Twitter_Link
      }


    
    }
  }
`
