import React, { useState, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./WhyUs.scss"
import ReactMarkdown from "react-markdown"
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { VideoPause, VideoPlayButton } from '@Components/icon/icon';
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const fromLeft = {
    hidden: {
        opacity: 0,
        x: -40,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const fromRight = {
    hidden: {
        opacity: 0,
        x: 40,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const Motionsection = motion(Row)

const WhyUs = (props) => {
    const vidRef = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isPlay, setPlay] = useState(false);
    if (props.Upload_Video !== null && props.Upload_Video !== "") {
        var video_url = props.Upload_Video?.url;
        video_url = video_url ? video_url : '';
      }
      const [showHTMLVideo, setHTMLVideo] = useState(true);
      const [muteVideo, setMuteVideo] = useState(true);
      const [play, setPlayvideo] = useState(true);
      const [controls, setVideoControls] = useState(false);
      const startplayvideo = (ref) => {

        setPlayvideo(true)
        // setVideoControls(false)
        setMuteVideo(false)
      }
      const showControls = () => {
        setVideoControls(true)
      }
      const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)
        setMuteVideo(true)
    
      }
    const [videoId, setVideoId] = useState('');
    // const [showVideo, setShowVideo] = useState(false);

    const playVideo = () => {
        var videoid = getVideoId(props?.Embed_Video_URL);
        setVideoId(videoid.id)
        setShowVideo(true)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <Motionsection className="whyus-section"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                {props.Alignment === "Right" &&
                                    <Col lg={7}>
                                        {/* <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'> */}
                                            <motion.div className={props.Alignment === "Right" ? "whyus-content right" : "whyus-content"} variants={titleVariants}>
                                                <span className="subtitle">{props.Title}</span>
                                                <h2>{props.Description}</h2>
                                                <ReactMarkdown source={props.Content} allowDangerousHtml />
                                            </motion.div>
                                        {/* </ScrollAnimation> */}
                                    </Col>
                                }
                                <Col lg={5} className={props.Alignment === "Right" ? "ml-lg-auto" : "ml-lg-auto left-img-alignment"}>
                                    <ScrollAnimation animateOnce={true} animateIn={props.Alignment === "Right" ? 'fadeInRight' : 'fadeInLeft'}>
                                    <motion.div className="" variants={fromRight}>
                                    {showHTMLVideo && play && controls && !muteVideo &&
        <div className="video-pause">
          <a onClick={() => pausevideo()}>  <VideoPause />
          </a>
        </div>
      }
                                                {showHTMLVideo &&
        <div className="html-vid">
          <ReactPlayer autoplay ref={vidRef}

            url={video_url}
            muted={muteVideo}
            loop={true}
            controls={false}
            autoplay={true}
            playsinline={true}
            playing={play}
            onEnded={() => pausevideo()}
            onClick={() => showControls()}
          />
        </div>
      }
                                        {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn"></div>}
                                        
                                            {showVideo &&
                                                <YouTube
                                                    video={videoId}
                                                    autoplay
                                                    onEnd={e => { setShowVideo(false) }}
                                                    modestBranding={1}
                                                    onPlaying={trackerVideo(props.Title)}
                                                    showRelatedVideos={false}
                                                    showInfo={false}
                                                    annotations={false}

                                                />
                                            }
                                            <picture>
                                                <source media="(min-width:992px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                <source media="(min-width:768px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                <img src={props.Image.url} alt={props.Image.alternativeText} />
                                            </picture>
                                            {props.Embed_Video_URL && !showVideo ?
                                                <div className="video-buttons">
                                                    <strong className="video-btn "
                                                        onClick={e => { playVideo(true) }}
                                                    >
                                                        <i className="icon-video ">
                                                        </i>
                                                    </strong>
                                                </div>
                                                : props.Upload_Video && muteVideo ?
                                                <div className="video-buttons"  onClick={() => {
                                                   setHTMLVideo(true); startplayvideo();
                                                 }}>
                                                   <strong className="video-btn">
                                                       <i className="icon-video ">
                                                       </i>
                                                   </strong>
                                               </div>
                                               :
                                               ''
                                            }

                                        </motion.div>
                                    </ScrollAnimation>
                                </Col>
                                {props.Alignment === "Left" &&
                                    <Col lg={7}>
                                        {/* <ScrollAnimation animateOnce={true} animateIn='fadeInRight'> */}
                                            <motion.div className={props.Alignment === "Left" ? "whyus-content left" : "whyus-content"} variants={fromLeft}>
                                                <span className="subtitle">{props.Title}</span>
                                                <h2>{props.Description}</h2>
                                                <ReactMarkdown source={props.Content} allowDangerousHtml />
                                            </motion.div>
                                        {/* </ScrollAnimation> */}
                                    </Col>
                                }
                            </Row>
                        </Container>
                    </Motionsection>
                )
            }}
        </InView>
    )
};

export default WhyUs